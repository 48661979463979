<script>
import Layout from "@/views/layouts/main";
import { users } from "@/config/api/users";
import { subscriptions } from "@/config/api/subscriptions";
import PageHeader from "@/components/page-header";
import UserModal from "@/components/users/user-modal";
import _ from "lodash";
import UpdateSub from "@/components/users/update-sub";
import { levels } from "@/config/api/levels";

export default {
  components: { Layout, UserModal, PageHeader, UpdateSub },
  data() {
    return {
      title: "Subscriptions",
      userData: [],
      selectedUser: null,
      userRoles: [],
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      activeTab: null,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "user", sortable: false },
        { key: "plan", sortable: false },
        { key: "subscription", sortable: false },
        { key: "createdAt", sortable: false },
      ],
      items: [
        {
          text: "Subscriptions",
          active: true,
        },
        {
          text: "Home",
        },
      ],
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },


  },
  watch: {
    filter() {
      this.searchForUser();
    },
  },
  mounted() { },
  created() {
    this.loadInfo()
  },
  methods: {
    findCertificate() {
      const api = levels.getAll;
      this.generateAPI(api)
        .then((res) => {
          this.subscriptionLevels = res.data.plans;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchForUser: _.throttle(function () {
      this.currentPage = 1;
      this.loadInfo();
    }, 2000),
    tabChanged(currentTabs) {
      this.currentTabs = currentTabs;
      this.loadInfo();
    },
    onFiltered() {
    },
    updatePage(page) {
      this.currentPage = page;
      this.loadInfo();
    },
    updatePageLimit(val) {
      this.perPage = val;
      this.loadInfo();
    },
    loadInfo() {
      this.loading = true;
      const api = subscriptions.get;
      let params = {
        page: this.currentPage,
        search: this.filter,
        limit: this.perPage,
      };


      api.params = params;
      this.generateAPI(api)
        .then((res) => {
          this.userData = res.data.message.docs;
          this.totalRows = res.data.message.totalDocs;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRowClicked(item) {
      this.$router.push({
        path: "/user-info",
        query: { id: item._id },
      });
    },
    editUser(data) {
      this.selectedUser = data;
      this.showActionModal();
    },
    showActionModal() {
      this.$bvModal.show("action-User");
    },
    hideActionModal() {
      this.$bvModal.hide("action-User");
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="clearfix"></div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-button id="addUsers" @click="showActionModal" pill variant="primary">Add Users</b-button>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions" @change="updatePageLimit">
                    </b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input v-model="filter" type="search" placeholder="Search..."
                      class="form-control form-control-sm ml-2"></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table tbody-tr-class="rowClass" class="table-centered" hover :items="userData" :fields="fields"
                responsive="sm" :busy="loading" >
                <template #table-busy>
                  <div style="text-align: center" class="mt-5 mb-5">
                    <pulse-loader color="#505d69"></pulse-loader>
                  </div>
                </template>
                <template #cell(user)="row">
                  <router-link :to="{path: '/user-info',query: { id: row.item.user_id._id },}">
                    {{ row.item.user_id.name || row.item.first_name}}<br />
                  </router-link>
                </template>
                <template #cell(plan)="row">
                  Name: {{row.item.name_en}} <br>
                  Frequency:
                  {{row.item.plan_id.frequency}} <br>
                  Free-Trial: {{row.item.plan_id.free_trial}} Days <br>
                  Price: {{row.item.plan_id.price}} AED <br>
                </template>
                <template #cell(subscription)="row">
                  Stripe Ref: {{row.item.subscription_payment_ref}} <br>
                  Amount Paid : {{row.item.price}} AED<br>
                  Subscription Started : {{row.item.starting_date && moment(row.item.starting_date).format('LL')}} <br>
                  Subscription Ended : {{row.item.ending_date && moment(row.item.ending_date).format('LL')}} <br>
                  Subscription Cancelled : {{row.item.canceled_date && moment(row.item.canceled_date).format('LL')}}<br>
                </template>
                <template #cell(phone_number)="row">
                  {{ row.item.country_code }}-{{ row.item.phone_number }}
                </template>
                <template #cell(createdAt)="row">
                  {{ moment(row.item.createdAt).format("LL") }}
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination :value="currentPage" :total-rows="rows" :per-page="perPage" @change="updatePage">
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UserModal :currentCompany="loadInfo" :selectedUser="selectedUser" v-on:resetModal="selectedUser = null"
      v-on:reloadData="loadInfo" v-on:closeModal="hideActionModal" />
  </Layout>
</template>

<style>
#addUsers {
  float: right;
  margin-top: 10px;
}
</style>
